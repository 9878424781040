import ReactDOM from 'react-dom/client';
import React from 'react';

const importComponent = (componentName) => {
  // const fileName = componentName.replace(/^[A-Z]/, l => l.toLowerCase())
  //                               .replace(/[A-Z]/g, l => `_${l.toLowerCase()}`);

  return import(`./${componentName}.jsx`).then((module) => module.default);
};

document.addEventListener('DOMContentLoaded', () => {
  const reactElements = document.querySelectorAll('[data-react-class]');

  reactElements.forEach((element) => {
    const componentName = element.dataset.reactClass;
    const props = JSON.parse(element.dataset.reactProps || '{}');

    window.importComponent(componentName).then((Component) => {
      const root = ReactDOM.createRoot(element);
      root.render(React.createElement(Component, props));
    }).catch((error) => {
      console.error(`Error loading component ${componentName}:`, error);
    });
  });
});

document.addEventListener('beforeunload', () => {
  const reactElements = document.querySelectorAll('[data-react-class]');

  reactElements.forEach((element) => {
    const root = ReactDOM.createRoot(element);
    root.unmount();
  });
});

export default importComponent;
